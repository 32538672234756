/* eslint-disable-next-line */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@font-face {
    font-family: 'Constantia';
    src: url('../src/assets/fonts/constantia.ttf') format('truetype');
  }

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    /* overflow-x: hidden; */
}

button[disabled],
span[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

ol.reset {
    list-style: lower-alpha;
    padding-left: 30px;
}

ol.reset li {
    line-height: 1.6;
}

.font-constantia {
    font-family: 'Constantia';
}

.font-arial {
    font-family: Arial, Helvetica, sans-serif;
}

.font-ubuntu {
    font-family: 'Ubuntu';
}

.fit-image {
    height: 90px;
    margin-left: 20px;
}

input#iAgree[type=checkbox] {
    position: relative;
    border: 2px solid #F76411;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 3px 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 18px;
    width: 18px;
    -webkit-appearance: none;
    opacity: .5;
}

input#iAgree[type=checkbox]:hover {
    opacity: 1;
}

input#iAgree[type=checkbox]:checked {
    background-color: #F76411;
    opacity: 1;
}

input#iAgree[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
}

.react-calendar__month-view__weekdays {
    padding: 5px;
    background: #fdf5ee;
    border-radius: 25px;
    color: gray;
    text-decoration: none;
    font-family: 'Ubuntu';
}

abbr:where([title]) {
    text-decoration: none;
    font-family: 'Ubuntu';
}

.react-calendar__tile--active {
    background: #f76411 !important;
    color: white;
    border-radius: 40px;
    font-family: 'Ubuntu';
}

.react-calendar__tile--active:hover {
    background: #f76411 !important;
    font-family: 'Ubuntu';
}

.react-calendar__tile {
    font-weight: 500 !important;
    font-family: 'Ubuntu';
}

.react-calendar__month-view__days__day--weekend {
    color: #000000 !important;
    font-family: 'Ubuntu';
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
    font-family: 'Ubuntu';
}

.react-calendar__navigation__label__labelText {
    font-weight: 500;
    font-family: 'Ubuntu';
    font-size: 18px;
}

.react-calendar__navigation__prev-button {
    order: 2;
    text-align: center;
    color: rgba(255, 255, 255, 0);
}

.react-calendar__navigation__next-button {
    order: 3;
    color: rgba(255, 255, 255, 0);
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    order: 4;
    display: none;
}

.react-calendar__navigation__prev-button::before {
    content: '\276E';
    color: #f76411;
}

.react-calendar__navigation__next-button::before {
    content: '\276F';
    color: #f76411;
}

.react-calendar__navigation__label {
    background: url(./assets/images/calender.png) !important;
    background-size: 30px 30px !important;
    background-repeat: no-repeat !important;
    background-position: 10% 5px !important;
}

.react-calendar {
    border: 0px solid #fff !important;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    aspect-ratio: 1/1;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    /* background-image: linear-gradient( yellow , red); */
    background-color: #fbc194;
    width: 6px;
    margin-left: 5px;
}

.findTherapist .swiper-button-prev {
    left: 205px;
    top: 43%;
}

.findTherapist .swiper-button-next {
    right: 0;
    top: 43%;
}

.findTherapist .swiper-button-prev,
.findTherapist .swiper-button-next {
    color: #fbc194;
    position: absolute;
    /* top: 46%; */
    /* top: 25vh; */
    top: 260px;
}

.findTherapist .swiper-button-next:after,
.findTherapist .swiper-button-prev:after {
    font-size: 40px;
    font-weight: 900;
}

.findTherapist .swiper-button-next.swiper-button-disabled,
.findTherapist .swiper-button-prev.swiper-button-disabled {
    opacity: 1 !important;
    cursor: pointer !important;
    pointer-events: all !important;
    display: block !important;
}

/* Added all Lucky's tailwind styles here */
.findTherapist {
    position: relative;
    margin-left: -10vw;
    height: 76.5vh;
    /* margin-top: -40px; */
    margin-right: 50px;
    padding-top: 100px;
    padding-right: 25px;
    /* max-width: 1250px;
    width: 1250px; */
    width: 1215px;
    clip-path: polygon(16% 0, 100% 0, 100% 100%, 16% 100%);
}

/* vk styles */
@media only screen and (min-width: 1380px) and (max-width:1445px) {

    .findTherapist,
    .findTherapistBookBtn {
        margin-left: -12vw;
    }
}

@media only screen and (max-width: 1366px) {
    .findTherapistWrapper {
        margin: unset;
    }

    .findTherapist {
        position: relative;
        margin-left: -18vw;
        height: 84vh;
        margin-top: -50px;
        margin-right: unset;
        padding-top: 100px;
        padding-right: 25px;
        max-width: 1250px;
        width: 1250px;
        clip-path: polygon(16% 0, 100% 0, 100% 100%, 16% 100%);
        transform: scale(0.8);
    }

    .findTherapist .swiper-button-prev {
        left: 210px;
    }

    .findTherapistBookBtn {
        max-width: 1250px;
        width: 1250px;
        margin-left: -17.6vw;
    }

    .findTherapistBookBtn button {
        margin-top: -11px;
    }

    .findTherapistFilterBy {
        transform: scale(0.8);
        height: 90vh;
        top: -11vh;
    }

    .findTherapistCalModal {
        transform: scale(0.8);
    }
}

@media only screen and (min-width: 1900px) {
    .findTherapistWrapper {
        margin: 0 auto;
    }

    .findTherapist {
        /* height: 60vh; */
        height: 55vh;
        margin-right: 50px;
    }

    /* .findTherapist .swiper-button-prev,
    .findTherapist .swiper-button-next {
        top: 43%;
    } */
}

@media only screen and (min-width: 1900px) and (min-height: 1200px) and (max-height: 1200px) {
    /* .findTherapist .swiper-button-prev,
    .findTherapist .swiper-button-next {
        top: 45%;
    } */
}

@media only screen and (min-width: 2200px) {
    .findTherapist {
        height: 45vh;
    }

    /* .findTherapist .swiper-button-prev,
    .findTherapist .swiper-button-next {
        top: 40%;
    } */
}

.richEditor {
    margin-top: 25px;
}

.richEditor .RichTextEditor__root___2QXK- {
    font-family: 'Ubuntu';
    background: transparent;
    border: 2px solid #FFDBB0;
    border-radius: 1.5rem;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    height: 68vh;
}

.richEditor .RichTextEditor__editor___1QqIU {
    cursor: text;
    font-size: 13px;
    position: absolute;
    top: 0;
    height: 70%;
    /* height: 154px; */
    width: 100%;
}

.richEditor .ButtonGroup__root___3lEAn>div>button {
    background: transparent;
    border: none;
}

.richEditor .EditorToolbar__root___3_Aqz {
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 4px;
    border-bottom: 1px solid #ddd;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    bottom: 0;
    border: none;
    display: flex;
}

.richEditorChat .RichTextEditor__root___2QXK- {
    border: none;
    margin: 0;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #ffc289 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #ffc289;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.carousalSwiper .swiper-button-prev,
.carousalSwiper .swiper-button-next {
    color: #404040;
    background: #ffffff;
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.carousalSwiper .swiper-button-next:after,
.carousalSwiper .swiper-button-prev:after {
    font-size: 14px;
    font-weight: bold;
}

/* Coach Welcome screen -- Calendar*/
.coach-calendar {
    padding: 0 30px;
}

.workingHours .swiper-button-prev,
.workingHours .swiper-button-next {
    color: #868686;
    background: white;
    opacity: 1 !important;
}

.workingHours .swiper-button-prev {
    left: 0;
}

.workingHours .swiper-button-next {
    right: 0;
}

/* Coach Welcome screen -- Client List */
.clientList {
    position: absolute;
    top: 60px;
    padding-top: 55px;
}

.clientList .swiper-button-prev,
.clientList .swiper-button-next {
    color: #fbc194;
    opacity: 1 !important;
    width: 30px;
}

.clientList .swiper-button-prev {
    left: unset;
    right: 50px;
    top: 10px;
}

.clientList .swiper-button-next {
    right: 0;
    left: unset;
    top: 10px;
}

.clientList .swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 25px;
    font-weight: 700;
}

/* Coach Welcome screen -- Recent Notes */
.recentNotes .swiper-button-prev,
.recentNotes .swiper-button-next {
    color: #868686;
    opacity: 1 !important;
}

.recentNotes .swiper-button-next,
.recentNotes .swiper-button-prev {
    position: absolute;
    bottom: 5px;
    right: 10px;
    top: unset;
    width: 30px;
    height: 30px;
}

.recentNotes .swiper-button-prev {
    right: 40px;
    left: unset;
}

.recentNotes .swiper-button-next:after,
.recentNotes .swiper-button-prev:after {
    font-size: 18px;
    font-weight: 799;
}

/* Coach Notes */
.hover-pin:hover .hover-img {
    opacity: 0.45;
}

.hover-pin:hover .hover-img:hover {
    opacity: 1;
}

.richEditor .EditorToolbar__root___3_Aqz {
    width: 100%;
    background-color: #ffdbb0;
}

.richEditor .RichTextEditor__editor___1QqIU .public-DraftEditor-content {
    height: 50vh;
}

.ButtonGroup__root___3lEAn {
    margin: 0 5px -10px 0 !important;
}

.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
    height: 20vh;
}

.richEditorChat .EditorToolbar__root___3_Aqz {
    width: 100%;
    background-color: #fff;
}

.lkjDVC {
    border-color: #f0c593 !important;
    border-radius: 15px !important;
}

.ant-modal-footer button {
    color: black;
    border: 1px solid #d9d9d9;
}

.ant-modal-footer button {
    color: black;
    border: 1px solid #d9d9d9;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: 140px !important;
    height: 70px !important;
    background-color: rgb(255 255 255) !important;
    border: 2px solid #ffdbb0 !important;
    color: #707480 !important;
}

/* New Bubble hover animation */
.categoryPillarInnerWrap {
    transform: translateY(0);
    transition: all 0.3s ease-out;
}

.categoryPillar:hover .categoryPillarInnerWrap {
    transform: translateY(-50px);
}

.categoryPillar.disabledVertical .categoryPillarInnerWrap {
    opacity: .6;
}

.categoryPillar.disabledVertical:hover .categoryPillarInnerWrap {
    /* transform: translateY(0); */
    cursor: no-drop;
}


/* .categoryPillar .activeLine {
    bottom: 30px;
    transition: all 0.3s ease-out;
} */
/* .categoryPillar:hover .activeLine {
    bottom: -5px;
} */
.aClass {
    right: 0px;
    top: -10px;
    position: absolute;
}

.example-rounded {
    /* border-radius: 20% !important; */
    padding: 3px !important;
}


/* Landing Page */
.testimonialsSwiper {
    position: relative;
}

.testimonialsSwiper .swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
    position: absolute;
    width: 150px;
    bottom: 47px;
    right: 25px;
    top: unset;
    left: unset;
    height: 3px;
    border-radius: 5px;
    background: #FDF5EE;
}

.testimonialsSwiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #F76411
}

.testimonialsSwiper .swiper-button-prev,
.testimonialsSwiper .swiper-button-next {
    top: unset;
    left: unset;
    bottom: 0;
    right: 75px;
    color: #FFC289;
}

.testimonialsSwiper .swiper-button-next {
    right: 18px;
}

.testimonialsSwiper .swiper-button-next:after,
.testimonialsSwiper .swiper-button-prev:after {
    font-size: 18px;
    font-weight: 100;
}

.uLine-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #F76411;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.uLine-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* Navbar creation */
.menu {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    transition-delay: 0.8s;
    visibility: hidden;
}

.menu ul li {
    font-family: 'Muli', sans-serif;
}

.menu ul li a {
    font-family: 'Muli', sans-serif;
}

.menu ul li a.active {
    color: #ccc;
}

.menu-sliders {
    flex: 1;
    transition: flex 0.8s;
}

.menu-sliders:nth-child(2) {
    flex: 1 0 100%;
    background: transparent;
}

.menu-sliders:nth-child(odd) {
    background: #000;
}

body.menu-open .menu-sliders:nth-child(2) {
    flex: 0 0 0%;
}

body.menu-open .menu {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    pointer-events: all;
}

body.menu-open .plus-btn .r1 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

body.menu-open .plus-btn .r2 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-135deg);
    transform: translateX(-50%) translateY(-50%) rotate(-135deg);
}

.menu-label {
    color: #fff;
    position: absolute;
    left: -70px;
    font-style: normal;
    letter-spacing: 2px;
    font-weight: 700;
}

.menu ul li:last-child {
    bottom: 10px;
    font-size: 14px;
    position: absolute;
    /* top: 10px; */
    right: 35px;
}

.menu ul li:last-child a {
    font-weight: 600;
    /* text-transform: uppercase; */
    letter-spacing: 3px;
}

.menu ul li:last-child span {
    display: inline-block;
    width: 20px;
    position: relative;
    top: -1px;
}

.menu ul li:last-child span>img {
    width: 100%;
}



/*Hamburger Icon Animation*/
#nav-icon1 {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

#nav-icon1 span {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
    top: 0px;
}

#nav-icon1 span:nth-child(2) {
    top: 9px;
    height: 3px;
}

#nav-icon1 span:nth-child(3) {
    top: 19px;
}

#nav-icon1.open span:nth-child(1) {
    top: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-icon1.open span:nth-child(3) {
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

@media( max-width:767px ){
    /* .testimonialsSwiper .swiper-horizontal>.swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal{
        right: unset;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, -50%);
    } */
    .testimonialsSwiper .swiper-horizontal>.swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal,
    .testimonialsSwiper .swiper-button-prev, .testimonialsSwiper .swiper-button-next {
        display: none;
    }
}